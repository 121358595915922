<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.sticPay.header') }}</p>
            <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">{{ $t('deposit.sticPay.form.header') }}</p>
            <el-form label-position="top" :model="sticPayForm" ref="sticPayForm" :rules="sticPayRules" status-icon>
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      supportedCurrencies="all"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, sticPayForm.amount)" prop="amount">
                      <numeric-input
                        v-model="sticPayForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('deposit.sticPay.form.email')" prop="email">
                      <el-input v-model="sticPayForm.email" data-testid="email"></el-input>
                    </el-form-item>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="sticPayForm.notes" data-testid="notes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <form id="sticPayForm" action="https://pay.sticpay.com/1.1/pay" method="post" style="display: none">
          <input name="callback_url" :value="sticpayApiForm.callback_url" />
          <input name="failure_url" :value="sticpayApiForm.failure_url" />
          <input name="interface_version" :value="sticpayApiForm.interface_version" />
          <input name="merchant_email" :value="sticpayApiForm.merchant_email" />
          <input name="order_amount" :value="sticpayApiForm.order_amount" />
          <input name="order_currency" :value="sticpayApiForm.order_currency" />
          <input name="order_no" :value="sticpayApiForm.order_no" />
          <input name="order_time" :value="sticpayApiForm.order_time" />
          <input name="referrer_url" :value="sticpayApiForm.referrer_url" />
          <input name="registered_email" :value="sticpayApiForm.registered_email" />
          <input name="sign" :value="sticpayApiForm.sign" />
          <input name="sign_type" :value="sticpayApiForm.sign_type" />
          <input name="success_url" :value="sticpayApiForm.success_url" />
        </form>
      </div>
      <div class="form-right">
        <div class="logo rectangle sticPay"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import { apiStic_payment } from '@/resource';
import mixin from '@/mixins/page/deposit';

export default {
  name: 'sticPay',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      sticPayForm: {
        accountNumber: '',
        amount: '',
        email: '',
        notes: ''
      },
      sticPayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      sticpayApiForm: {
        callback_url: null,
        failure_url: null,
        interface_version: null,
        merchant_email: null,
        order_amount: null,
        order_currency: null,
        order_no: null,
        order_time: null,
        referrer_url: null,
        registered_email: null,
        sign: null,
        sign_type: null,
        success_url: null
      },
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst2', { method: this.$t('common.withdrawChannel.sticpay') }),
        this.$t('deposit.reminder.inst3', { method: this.$t('common.withdrawChannel.sticpay') })
      ],
      showNote: false
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.sticPayForm.accountNumber = accountNumber;
    },
    submitForm(e) {
      this.$refs['sticPayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                this.sticpayApiForm = result.data.data.sticParams;
                this.$nextTick(function() {
                  if (document.getElementById('sticPayForm')) {
                    document.getElementById('sticPayForm').submit();
                  } else {
                    this.loading = false;
                    this.errorMessage(this.$t('deposit.default.failed'));
                  }
                });
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiStic_payment(
        {
          mt4Account: this.sticPayForm.accountNumber,
          operateAmount: this.sticPayForm.amount,
          applicationNotes: this.sticPayForm.notes,
          skrillEmail: this.sticPayForm.email
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
